import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Sizing from "@/components/general/Sizing"
import MenuList from "@/components/general/MenuList"
import SEO from "@/components/seo"
import Cursor from "@/components/general/Cursor"
import styles from "@/styles/index.module.scss"
import logo from "@/images/logo-sq_spanish.png"

import boredom from "@/images/covers/_boredom.jpg"
import racism from "@/images/covers/_racism.jpg"
import disease from "@/images/covers/_disease.jpg"
import climate from "@/images/covers/_climate.jpg"
import hunger from "@/images/covers/_hunger.jpg"
import rents from "@/images/covers/_rents.jpg"
import defaultImg from "@/images/covers/_default.jpg"
import shareImg from "@/images/og/og_share_spanish.jpg"

const IndexPage = ({pageContext}) => {
    const [cover, setCover] = useState("default")
    const [mouseActive, setMouseActive] = useState(false)
    const ref = useRef()

    useEffect(() => {
        console.log(cover)
    }, [cover])

    useEffect(() => {
        const mouseenter = () => {
            setMouseActive(true)
        }

        const mouseleave = () => {
            setMouseActive(false)
        }

        ref.current.addEventListener("mouseenter", mouseenter)
        ref.current.addEventListener("mouseleave", mouseleave)

        return () => {
            ref.current.removeEventListener("mouseenter", mouseenter)
            ref.current.removeEventListener("mouseleave", mouseleave)

            setMouseActive(false)
        }
    }, [])

    return (
        <>
            <SEO
                title="Free Markets Destroy | Un Proyecto de Washington Policy Center"
                description="En una amplia gama de temas importantes, nuestro mundo necesita desesperadamente un progreso real. Descubra cómo los mercados pueden ayudarnos a construir un mundo mejor."
                img={shareImg}
                lang="es"
            />

            <div className={styles.main}>
                <Sizing
                    styles={{
                        position: "fixed",
                        height: "100%",
                        left: 0,
                        top: 0,
                    }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 12,
                                display: "block",
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                width: 13,
                            },
                        },
                    ]}
                >
                    <div className={styles.headerPadding}>
                        <div className={styles.wrap}>
                            <div className={styles.covers}>
                                <img src={defaultImg} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${
                                    styles.boredom
                                } ${cover === "boredom" ? styles.active : ""}`}
                            >
                                {" "}
                                <img src={boredom} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${
                                    styles.climate
                                } ${cover === "climate" ? styles.active : ""}`}
                            >
                                {" "}
                                <img src={climate} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${
                                    styles.disease
                                } ${cover === "disease" ? styles.active : ""}`}
                            >
                                {" "}
                                <img src={disease} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${styles.hunger} ${
                                    cover === "hunger" ? styles.active : ""
                                }`}
                            >
                                {" "}
                                <img src={hunger} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${styles.rents} ${
                                    cover === "rents" ? styles.active : ""
                                }`}
                            >
                                {" "}
                                <img src={rents} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${styles.rents} ${
                                    cover === "racism" ? styles.active : ""
                                }`}
                            >
                                {" "}
                                <img src={racism} alt="" />{" "}
                            </div>
                        </div>
                    </div>
                </Sizing>

                <Sizing
                    styles={{ boxSizing: "border-box", float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                // marginLeft: 13,
                                width: 24,
                                padding: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                // marginLeft: 13,
                                width: 12,
                                padding: 1.5,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                // marginLeft: 13,
                                width: 11,
                                padding: 1.5,
                            },
                        },
                    ]}
                >
                    <div className={"copy--lead " + styles.mobileBg}>
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        display: "block",
                                        width: 20,
                                        paddingTop: 8,
                                        paddingLeft: 1.5,
                                        paddingRight: 1.5,
                                        // paddingBottom: 1.5,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        display: "none",
                                    },
                                },
                            ]}
                        >
                            <img style={{ width: "100%" }} src={logo} alt="" />
                        </Sizing>

                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        padding: 1.5,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        padding: 0,
                                    },
                                },
                            ]}
                        >
                            <p>
                                Enfermedad, hambre, cambio climático. A través de una amplia gama de temas importantes, nuestro mundo necesita desesperadamente un progreso real. Nuestra política es disfuncional, llevada a extremos por la polarización y amañada para beneficiar a los políticamente conectados.
                            </p>
                        </Sizing>
                    </div>
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    padding: 1.5,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    padding: 0,
                                },
                            },
                        ]}
                    >
                        <p className={"copy"}>
                            Aunque hemos logrado avances increíbles como sociedad en lo que respecta a los derechos civiles, a demasiadas personas se les niega el acceso a oportunidades transformadoras: empleos, atención médica, vivienda asequible. Necesitamos derribar lo que está roto en nuestra política. Necesitamos destruir las barreras que se interponen en el camino de la creación de un mundo mejor.
                        </p>

                        <div className={"copy"}>
                            <p>
                                ¿Cómo podemos llevar a cabo esta revolución en el progreso humano y sostenerla?, ¿Qué pasa si la respuesta está en lo que muchos consideran una fuente importante de nuestros problemas: el capitalismo de libre mercado?, ¿Qué pasaría si los mercados libres hicieran más que llenar nuestros carritos de Amazon con nuevos articulos?, ¿Qué pasaría si los mercados destruyeran los privilegios y la pobreza?, ¿Y si los mercados mejoraran todo?
                            </p>
                        </div>
                    </Sizing>
                    <div className={styles.menuSection}>
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        paddingTop: 2,
                                        paddingLeft: 1.5,
                                        paddingRight: 1.5,
                                        paddingbottom: 1.5,

                                        // marginBottom: 1,
                                    },
                                },

                                {
                                    min: 1024,
                                    val: {
                                        paddingTop: 2,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingbottom: 0,

                                        // marginBottom: 1,
                                    },
                                },
                            ]}
                        >
                            <Sizing
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            width: 16,
                                            marginBottom: 0.5,
                                        },
                                    },
                                    {
                                        min: 1024,
                                        val: {
                                            width: 6,
                                            marginBottom: 0.5,
                                        },
                                    },
                                ]}
                            >
                                <span className={styles.intro + " copy"}>
                                    Elija un tema para aprender cómo los mercados pueden ayudarnos a construir un mundo mejor.
                                </span>
                            </Sizing>
                            <div className={"hand"} ref={ref}>
                                <MenuList setSelection={setCover} langKey={pageContext.langKey}/>{" "}
                            </div>
                        </Sizing>
                    </div>
                </Sizing>
            </div>

            <Cursor active={mouseActive}></Cursor>
        </>
    )
}

export default IndexPage
